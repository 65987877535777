<template>
  <div id="simple-calendar-app">

    <booking-preview ref="preview" :bookingPreviewActive.sync="bookingPreviewActive" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" @openEditBookingPopUp="editData"/>

    <booking-form-pop-up :bookingFormPopUpActive.sync="bookingFormPopUpActive" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" :isEdit="isBookingEdit" :startDate="startDate" :endDate="endDate" :enableBlockBooking.sync="enableBlockBooking"/>

    <booking-confirmation :bookingConfirmationPopUp.sync="bookingConfirmationPopUp" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" @openPreview="bookingPreviewActive = true" :bookingConfirmationType="bookingConfirmationType"/>


    <div class="vx-card no-scroll-content">
      <calendar-view
      ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :events="calendarEvents"
        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
        @click-date="addNewBookingData"
        @click-event="showData"
      >

      <div slot="header" class="mb-4">

        <div class="vx-row no-gutter" id="calendar">

          <!-- Month Name -->
          <div class="vx-col w-1/3 items-center sm:flex hidden">
            <div class="w-full">
              <div class="vx-col  w-full md:mb-2 pl-2 mt-2 required">
                  <label class="v-select-label">{{$t('pages.superCalendar.selectBranches')}}</label>
                  <v-select
                    multiple
                    class="v-select-background"
                    v-model="selectedBranches"
                    :options="branches"
                    label="arabic_name"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    @input="branchesUpdated"
                  />
                </div>

            </div>
          </div>

            <!-- Current Month -->
            <div class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last">
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full" />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{ showDate | month }}</span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full" />
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  v-if="calendarView === view.val"
                  :key="String(view.val) + 'filled'"
                  type="filled"
                  class="p-3 md:px-8 md:py-3"
                  :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button>
                <vs-button
                  v-else
                  :key="String(view.val) + 'border'"
                  type="border"
                  class="p-3 md:px-8 md:py-3"
                  :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                  @click="calendarView = view.val"
                  >{{ view.label }}</vs-button>
              </template>

            </div>
          </div>
        </div>
      </calendar-view>
    </div>

  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'

// import moduleCalendar from '@/store/calendar/moduleCalendar.js'
import moduleDataList from '@/store/data-list/moduleDataList.js'
import BookingFormPopUp from '@/views/q-pages/Booking/BookingFormPopUp'
import BookingConfirmation from '@/views/q-pages/Booking/BookingConfirmation'
require('vue-simple-calendar/static/css/default.css')

import vSelect from 'vue-select'

var moment = require('moment');

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    BookingFormPopUp,
    BookingConfirmation,
    vSelect
  },
  data () {
    return {
      showDate: new Date(),

      // to be removed
      id: 0,
      title: '',
      startDate: '',
      endDate: '',
      labelLocal: 'none',

      url: '',
      calendarView: 'month',

      calendarViewTypes: [
        {
          label: 'Month',
          val: 'month'
        },
        {
          label: 'Week',
          val: 'week'
        }
      ],

      currentBooking: null,
      isBookingEdit: false,

      // sidebar
      newBooking: {},
      bookingPreviewActive: false,

      // booking form pop up
      bookingFormPopUpActive: false,
      bookingId: null,

      // booking block
      bookingBlockPromptActive: false,

      enableBlockBooking: false,

      bookingConfirmationPopUp: false,
      bookingConfirmationType: 'bookingConfirmation',


      selectedBranches: [],
    }
  },
  methods: {
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
      const date = moment(this.showDate).format('yyyy-MM-DD')
      this.getCalendarBookings(date, this.selectedBranches)
    },
    addNewBookingData() {
      console.log('Adding Booking!!');
    },
    showData(data) {
      if(data.cancelation_reason == null)
        data.cancelation_reason = 'unavailable_date';
      this.currentBooking = data.originalEvent ? data.originalEvent : data
      this.addBookingIdQueryParameter(this.currentBooking.id, this.currentBooking.venue_id);
      let clickedState = this.currentBooking.state.name;
      this.bookingFormPopUpActive = false;
      if(this.currentBooking.state_id == 8 || this.currentBooking.state.name == 'booking_check_rejected') { // check if booking block
        this.bookingBlockPromptActive = true
      } else if(this.bookingConfirmationStatesNames && this.bookingConfirmationStatesNames.includes(clickedState)) {
        this.bookingConfirmationType = clickedState == 'booking_check' ? 'bookingCheck' : ( clickedState == 'visit' ? 'bookingVisit' : 'bookingConfirmation');
        this.bookingConfirmationPopUp = true;
      } else {
        this.bookingPreviewActive = true;
      }
    },
    editData (data) {
      this.currentBooking = data
      this.bookingFormPopUpActive = true;
      this.bookingPreviewActive = false;
      this.isBookingEdit = true;
    },
    getCalendarBookings(date, agentVenues = []) {
      console.log(agentVenues);
      const ids = agentVenues.map(obj => obj.id);
      this.$store.dispatch('dataList/fetchCalendarBookingsMultiVenues', {ids, date})
    },
    branchesUpdated() {
      const date = moment(this.showDate).format('yyyy-MM-DD')
      this.getCalendarBookings(date, this.selectedBranches)
    }
  },
  created() {
    const date = this.$route.params.date || moment().format('yyyy-MM-DD')

    const agentVenues = localStorage.getItem('agentVenues') ? JSON.parse(localStorage.getItem('agentVenues')) : [];

    this.selectedBranches = agentVenues;

    this.getCalendarBookings(date, this.selectedBranches)

    window.addEventListener('changeLanguage', function(e) {
      //this.$store.dispatch('dataList/runLoading'),
      Promise.all([
        this.$store.dispatch('dataList/fetchBookingStatesList'),
      ]).then(()=>{this.$store.dispatch('loader/loaderOff')});
    }.bind(this));

    this.$store.dispatch('bookingStates/getBookingStatesArrayByArrayName', {arrayName: 'bookingConfirmationStatesAgent'})

  },
  mounted() {
    const daysHeader = document.getElementsByClassName('cv-header-days')[0]
    daysHeader.style.setProperty('direction','ltr','important')
  },

  computed: {
    isLoading() {
      return this.$store.getters.isLoading
    },
    currentVenueId() {
        return this.$store.getters.currentVenueId
    },
    calendarEvents() {
      const events = []
      const venueId = this.currentVenueId;
      if(this.bookings.length){
        this.bookings.forEach(booking => {
          const event = {...booking, venue_id: booking.venue_id, startDate: booking.start_date, id: booking.id, booking_id:booking.id, endDate: booking.end_date, title: booking.customer_name, style:`background: ${booking.state.color}` }
          if(event.added_by == "admin"){

            event.title = "A| "+event.title
          }else if(event.added_by == "customer"){
            event.title = "Q| "+event.title
          }else{
            event.title = "Ag| "+event.title
          }
          if(event.state_id == 8) {
            event.title = this.$t('bookingBlock')
          }
          if(booking.state.name != 'booking_check_accepted')
            events.push(event)
        });

      }
      return events
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    bookings() {
      return this.$store.state.dataList.multiVenueBookings
    },
    bookingStates() {
      return this.$store.state.dataList.bookingStates
    },
    bookingConfirmationStates() {
        return this.$store.state.bookingStates.bookingConfirmationStates;
    },
    bookingConfirmationStatesNames() {
        return this.bookingConfirmationStates.map(state => state.name);
    },
    branches() {
      return localStorage.getItem('agentVenues') ? JSON.parse(localStorage.getItem('agentVenues')) : [];
    }
  },
  watch: {
    isLoading(newVal){
      if (newVal == true){
        this.$store.dispatch('loader/loaderOff')
      }else {
        this.$store.dispatch('loader/loaderOff')
      }

    }
  }
}
</script>

<style lang="scss">
.cv-header-days{
    direction: ltr !important;
  }

  [dir=rtl] .cv-header-days{
    direction: ltr !important;
  }

  @import "@/assets/scss/vuexy/apps/simple-calendar.scss";

  #app .cv-header-days{
    direction: ltr !important;
  }
</style>
